body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

//The 2 styles below helps avoid a bug where wrong ghost image is displayed while dragging nodes because of the tooltips on the node.
.__react_component_tooltip_custom {
  &.show {
    display: inline-block !important;
  }

}

#delete-response-tt,
#add-child-tt,
#edit-tt,
[id^="portal-tt"] {
  display: none !important
}