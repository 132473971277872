.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 5s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.app-bar{
  -moz-transition: left 218ms cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: left 218ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: left 218ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: left 218ms cubic-bezier(0.4, 0, 0.2, 1);
  left: 0;
  width: auto !important;
  right: 0 !important;
  position: fixed !important;
}

.app-content{
  -moz-transition: padding-left 218ms cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: padding-left 218ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: padding-left 218ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: padding-left 218ms cubic-bezier(0.4, 0, 0.2, 1);
  padding-right: 20px !important;
  padding-top: 64px !important;
}


.app-content.expanded{
  padding-left: 273px;
}

.app-content.expanded-flowchart{
  padding-left: 255px;
}

.app-content.expanded-settings{
  padding-left: 256px;
  padding-right: 0px !important;
}

.linkedRepliesNode{
  padding-top: 7px;
  -webkit-margin-after: 0px;
}

.node-name{
  padding-top: 4px;
}

.linkedRepliesList{
  -webkit-padding-start: 0px;
  list-style-type: none;
}

.linkedRepliesTextarea{
  width: 90% !important;
}

.float-left{
  float: left
}

.float-right{
  float: right
}

.modal-title{
  display: block;
  margin: 0 auto;
}

.modal-form-group{
  text-align: center;
  padding: 10px 0 10px 0;
}

.modal-button{
  margin-top: 10px;
  text-align: center;
}

.footer-text{
  color: red;
  font-weight: bold
}

.active {
  border-color: #26568D;
  border-style: solid;
  border-width: 4px;
}

.past {
  border-color: #dbdbdd;
  border-style: solid;
  border-width: 4px;
}

.expired {
  border-color: #8a0505;
  border-style: solid;
  border-width: 4px;
}

.menuItemSearchResult {
  background-color: rgba(38, 86, 141, 0.39);
  border-left: 4px solid orange;
  border-radius: 10px;
}